<template>
  <Card title="权限管理">
    <div>
      <a-form-model
        layout="inline"
        @keyup.enter.native="query"
        style="margin-bottom: 2vh"
      >
        <a-form-model-item>
          <a-input v-model="form.code" placeholder="权限值" />
        </a-form-model-item>
        <a-form-model-item>
          <a-input v-model="form.name" placeholder="名称" />
        </a-form-model-item>

        <a-form-model-item>
          <a-space>
            <a-button type="primary" @click="query">查询</a-button>
            <a-button @click="reset">重置</a-button>
          </a-space>
        </a-form-model-item>
      </a-form-model>

      <a-table
        bordered
        :dataSource="list"
        @change="onChange"
        :loading="loading"
        :pagination="{
          total,
          current,
          pageSize,
          showTotal: (total) => `共 ${total} 条记录`,
        }"
        rowKey="code"
      >
        <a-table-column
          key="name"
          title="名称"
          data-index="name"
        ></a-table-column>
        <a-table-column
          key="code"
          title="权限值"
          data-index="code"
        ></a-table-column>
        <a-table-column
          key="group"
          title="所属组"
          data-index="groupName"
        ></a-table-column>
        <a-table-column
          key="path"
          title="接口路径"
          data-index="path"
        ></a-table-column>
        <a-table-column
          key="remarks"
          title="备注"
          data-index="remarks"
        ></a-table-column>
      </a-table>
    </div>
  </Card>
</template>

<script>
import { fetchList } from "@/api/setting/permission";

export default {
  data() {
    return {
      form: {},
      loading: false,
      current: 1,
      pageSize: 10,
      list: [],
      total: 0,
    };
  },

  mounted() {
    this.getList();
  },

  methods: {
    getList() {
      this.loading = true;
      const { current, pageSize } = this;
      fetchList({
        pageNum: current,
        pageSize,
        ...this.form,
      })
        .then((res) => {
          if (Array.isArray(res.list)) {
            this.list = Object.freeze(res.list);
            this.total = res.totalSize;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.getList();
    },

    query() {
      this.current = 1;
      this.getList();
    },
    reset() {
      this.form = {};
      this.current = 1;
      this.getList();
    },
  },
};
</script>